import React from 'react'
import { Layout } from '../containers/Layout'
import { Text } from '../components/Text'
import { Container } from '../components/grid/Container'
import { Separator } from '../components/Separator'
import { MobileContent } from '../components/grid/MobileContent'
import { Block } from '../components/Block'
import { LinkedInIcon } from '../partials/LinkedInIcon'
import CodesImg from '../images/quien-soy/codes1.jpg'
import { Image } from '../components/Image'
import { HtmlContent } from '../components/HtmlContent/HtmlContent'
import { media } from '../theme/mixins/media'
import styled from 'styled-components/macro'
import { rem } from 'polished'
import { sizes } from '../theme'
import { TarifaSection } from '../partials/index/TarifaSection'
import { Mapa } from '../partials/index/Mapa'
import { graphql } from 'gatsby'
import { GraphqlErrorList } from '../components/GraphqlErrorList'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'
import { PortableText } from '../components/PortableText'
import { Seo } from '../partials/Seo'

export const query = graphql`
  query FindTecnica($id: String!) {
    sanityTecnica(id: { eq: $id }) {
      _rawBody
      imagenBig {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
      nombre
    }
  }
`

const Page = (props: any) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphqlErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.sanityTecnica

  return (
    <Layout fullWidth>
      <Seo title={`${page.nombre} - Centro Feeling`} />
      <Container>
        <Text size="h1" family="merienda" center>
          {page.nombre}
        </Text>
        <Separator />

        <BodyWrapper>
          <Block marginBottom="size7">
            <Image
              ratio="16by9"
              src={imageUrlFor(buildImageObj(page.imagenBig))
                .width(700)
                .height(Math.floor((9 / 16) * 700))
                .auto('format')
                .url()}
            />
          </Block>

          <MobileContent>
            <HtmlContent>
              <Content>
                <PortableText blocks={page._rawBody} />
              </Content>
            </HtmlContent>
          </MobileContent>
        </BodyWrapper>
      </Container>
      <TarifaSection />
      <Mapa />
    </Layout>
  )
}

const BodyWrapper = styled.div`
  ${media.tablet`
    max-width: ${rem(700)};
    margin-left: auto;
    margin-right: auto;
  `}
`

const Content = styled.div`
  margin-bottom: ${rem(sizes.size9)};
  font-weight: 300;
`
export default Page
